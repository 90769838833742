<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>对象：</span>
        <el-select  class="mr-20" v-model="queryParams.IsTeacher" placeholder="请选择打卡对象">
          <el-option label="学生" :value="false"></el-option>
          <el-option label="老师" :value="true"></el-option>
        </el-select>
        <span>项目班：</span>
        <el-select class="mr-20" v-model="queryParams.LayoutId" clearable>
          <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
        </el-select>
        <span>日期：</span>
        <el-date-picker v-model="queryParams.Date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" class="mr-20" type="date" @change="change" />
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading" height="640px">
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :show-overflow-tooltip="true" :prop="item.prop" :width="needWidth(item.prop)">
          <template slot-scope="scope">
            <span v-if="item.prop === 'Name'">
              {{ scope.row['StudentName']|| scope.row['TeacherName'] }}
            </span>
            <span v-else-if="item.prop === 'RuleName'" style="text-overflow: ellipsis;overflow: hidden;">
              {{ scope.row[item.prop] }}
            </span>
            <span v-else-if="item.prop === 'SignDate'">
              {{formartDate(scope.row["SignDate"], "day")}}
            </span>
            <span v-else-if="item.prop === 'SignInTime'">
              {{ scope.row[item.prop] }}
            </span>
            <span v-else-if="item.prop === 'SignOutTime'">
              {{ scope.row[item.prop] }}
            </span>
            <span v-else-if="item.prop === 'SignInRuleTime' && scope.row[item.prop]">
              {{ scope.row[item.prop] }}
            </span>
            <span v-else-if="item.prop === 'SignOutRuleTime' && scope.row[item.prop]">
              {{ scope.row[item.prop] }}
            </span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <!-- <el-table-column label="操作" width="100px">
          <template slot-scope="scoped">
            <el-button
              type="text"
              icon="el-icon-view"
              class="btn-color"
              @click="handleView(scoped.row.Id)"
              >查看</el-button
            >
          </template>
        </el-table-column> -->
    </el-table>
    <pagination class="u-flex u-row-right" v-show="total > 0" :total="total" :page.sync="queryParams.PageIndex" :limit.sync="queryParams.PageSize" @pagination="getTableData" />
  </div>
</template>

<script>
import { getPageList } from "@/api/signRecord";
import { queryApplyJobCatelog } from "@/api/jobApply";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
import { getAllList as getProjectClassList } from "@/api/internshipLayout";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
export default {
  components: {
    Pagination
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      queryParams: {
        IsTeacher: false,
        PageSize: 10,
        PageIndex: 1,
        Date: new Date()
      },
      total: 0,
      tableData: [],
      tableConfig: [
        {
          label: "姓名",
          prop: "Name"
        },
        {
          label: "打卡规则",
          prop: "RuleName"
        },
        {
          label: "日期",
          prop: "SignDate"
        },
        {
          label: "上班时间",
          prop: "SignInRuleTime"
        },
        {
          label: "上班打卡时间",
          prop: "SignInTime"
        },
        // {
        //   label: "上班打卡地点",
        //   prop: "SignInPlace"
        // },
        {
          label: "上班打卡状态",
          prop: "SignInStatus"
        },
        {
          label: "下班时间",
          prop: "SignOutRuleTime"
        },
        {
          label: "下班打卡时间",
          prop: "SignOutTime"
        },
        // {
        //   label: "下班打卡地点",
        //   prop: "SignOutPlace"
        // },
        {
          label: "下班打卡状态",
          prop: "SignOutStatus"
        },
        {
          label: "状态",
          prop: "StatusStr"
        }
      ],
      jobList: [],
      projectClassList: []
    };
  },
  async created() {
    await this.initialize();
    // await this.getJobList();
    await this.getProjectClassList();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        // if (this.RoleType === StaffTypeEnum.School) {
        //   let temp = this.employee.find(el => el.StaffType === this.RoleType);
        //   this.queryParams.SchoolId = temp.Employer.Id;
        // } else {
        //   this.queryParams.SchoolId = null;
        // }
        await this.getTableData();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const { Result: { Data = [], TotalCount } } = await getPageList(this.queryParams);
      this.total = TotalCount;
      this.tableData = Data;
    },
    handleView(id) {
      console.log(id);
    },
    needWidth(val) {
      const arr180 = [
        "RuleName",
        "SignInTime",
        "SignOutTime",
        "SignInStatus",
        "SignOutStatus",
        "SignInPlace",
        "SignOutPlace"
      ];
      const arr100 = ["Name", "SignDate", "Age", "ActualStartTime", "JobName"];
      if (arr180.indexOf(val) !== -1) {
        return 180;
      } else if (arr100.indexOf(val) !== -1) {
        return 120;
      } else {
        return;
      }
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async getProjectClassList() {
      const { Result = [] } = await getProjectClassList();
      this.projectClassList = Result;
    },
    change(e){
console.log(`e`, e)
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
