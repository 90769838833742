import request from "@/utils/request";
const controller = "/VocationalEducation/SignRecord";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/getAllList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });
const getSignDetailPageList = params =>
  request({
    url: `/VocationalEducation/SignRecordDetail/GetPageList`,
    method: "get",
    params
  });

export { getPageList, getAllList, getInfo, getSignDetailPageList };
